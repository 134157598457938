import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import { Link } from "gatsby"
import '../components/layout/layout.css'

import Seo from "../components/seo"
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import styled from "styled-components"
import { Image } from '../components/Images'
import { useBreakpoint } from "gatsby-plugin-breakpoints"


import RectComponent from '../components/linktree/RectComponent'
import LinktreeHeader from "../components/linktree/header"
import TopIcons from "../components/linktree/topIcons"
import LinktreeSocialNetwork from "../components/linktree/SocialNetworks"
import LinkTreePopUp from "../components/linktree/LinkTreePopUp"

const LinkTreePage = ({ data }) => {

    const { t } = useTranslation()
    const breakpoints = useBreakpoint();
    const { language } = useI18next()

    var dataLinktree = t("linktree", { returnObjects: true })

    const [open, setOpen] = useState(false)
    var comerciais = dataLinktree.popup.infos
    const topIcons = dataLinktree.topIcons

    const [actual, setActual] = useState(0);
    const [toRemove, setToRemove] = useState(0);

    useEffect(() => {
        var actualAux = actual;
        var toRemoveAux = toRemove;
        const timer = setTimeout(() => {

            if (actualAux === toRemoveAux) {
                if (typeof document !== 'undefined') {
                    const elems = document.getElementsByClassName("bg-white");
                    elems[actualAux].classList.add("hover-effect")
                }
                actualAux = 1
                setActual(actualAux)

            } else if (actualAux !== toRemoveAux) {
                if (actualAux === 0) {
                    toRemoveAux = 3
                    setToRemove(3)
                }
                else if (actualAux === 4) {
                    actualAux = 0
                    setActual(0)
                }
                else if (toRemove === 4) {
                    toRemoveAux = 0
                    setToRemove(0)
                }

                if (typeof document !== 'undefined') {
                    const elems = document.getElementsByClassName("bg-white");
                    elems[toRemoveAux].classList.remove("hover-effect")
                    elems[actualAux].classList.add("hover-effect")
                }

                toRemoveAux = actualAux
                setToRemove(actualAux)
                actualAux++
                setActual(actualAux)
            }

        }, 2000);
        return () => clearTimeout(timer);
    }, [actual, toRemove]);

    // FILTRAR OS ARTICLES CONSOANTE A LINGUA SELECTIONADA
    const filteredArticles = data.allWpPost.nodes.filter(function (article) {
        let articleLanguage = ''

        article.tags.nodes.map(slug => (
            articleLanguage = slug.slug
        ))

        return articleLanguage === language
    })

    // GET LAST ARTICLE
    var article = filteredArticles.slice(0, 1).shift()

    return (
        <>
            <Seo title="Linktree" img={(<Image
                src={dataLinktree.logo}
                alt='Full Logo Ponto Urbano'
            />)?._source?.fileName} />


            <LinkTreeStyled >

                <LinktreeHeader src={dataLinktree.logo}
                    alt='Full Logo Ponto Urbano' />

                <div className="full-body">
                    <Image
                        src={breakpoints.l ? dataLinktree.bgmobile : dataLinktree.bg}
                        alt='Full Logo Ponto Urbano' prev={dataLinktree.prev} className="fullscreen"
                    />

                    <TopIcons topIcons={topIcons} />

                    <div className="container-cards">
                        {t("home", { returnObjects: true }).images.map((image, index) => (
                            <Link to={image.link}>
                                <RectComponent key={image.logo} image={image.image} logo={image.logo} reverse={index % 2 === 0 ? false : true}
                                    prev={dataLinktree.prev}
                                />
                            </Link>
                        ))}
                        <Link to={'/noticias' + article.uri}>
                            <RectComponent image={article?.featuredImage?.node?.localFile?.childImageSharp.gatsbyImageData.images.fallback.src} srcImage={true} blog
                                logo={dataLinktree.blog.icon} reverse={true} prev={dataLinktree.prev} />
                        </Link>
                    </div>

                    <LinktreeSocialNetwork linktree={dataLinktree}
                        open={open} setOpen={setOpen} comerciais={comerciais} />

                    <LinkTreePopUp open={open} setOpen={setOpen} linktree={dataLinktree} />
                    
                </div>
            </LinkTreeStyled>
        </>
    )
}

export default LinkTreePage

export const pageQuery = graphql`
    query($language: String!) {
        locales: allLocale(filter: {ns: {in: ["news","home", "global", "linktree", "portfolio"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allWpPost(sort: { fields: [date], order: DESC }) {
            nodes {
            uri
            date(formatString: "dddd | DD MMMM YYYY", locale: "pt")
            title
            content
            excerpt
            categories {
                nodes {
                  name
                }
            }
            tags {
                nodes {
                    slug
                }
            }
            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: BLURRED
                                layout: FULL_WIDTH
                                formats: [JPG]
                                quality: 100
                            )
                        }
                    }
                }
            }
            }
        }
    }`

const LinkTreeStyled = styled.div`
    margin: 0px;
    position: absolute;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden !important;
    left: 0;
    z-index: -23;

    h1 {
    text-align: center;
    font-family: system-ui, sans-serif;
    font-size: 3rem;
    word-spacing: .8em;
  }
  h1 span:first-child {
    color: #af3817;
  }
  h1 span:last-child {
    color: #0b3fa1;
  }
  .container-full-logo{
    width: 100%;
    height: 10vh;
    margin: auto;
    position: fixed;
    top: 0;
    background-color: #000000;
    .center{
        height: 10vh;
        align-items: center;
        align-content: center;
        vertical-align: middle;
        display: grid;
        position: relative;
        .image{
            position: relative;
            object-fit: revert;
            height: 100%;
            width: 100%;
            max-height: 100%;
            max-width: 35vh;
            margin: auto;
            align-items: center;
            align-content: center;
            align-self: center;
            alignment-baseline: central;
            vertical-align: middle;
        }
    }
  }
  .fullscreen{
    height: 100vh;
    width: 100%;
    object-fit: cover;
    max-height: 100vh;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
  }
  .full-body{
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: -5;
    .image{
        position: relative;
    }
    .center{
        height: 5vh;
        align-items: center;
        align-content: center;
        vertical-align: middle;
        display: grid;
        position: absolute;
        top: 11vh;
        width: 100%;
        margin: auto;
        text-align: center;
    }
  }
  .container-top-icons{
    height: 5vh;
    width: 50vh;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(5,1fr);
    a{
        display: grid;
        vertical-align: middle;
        margin: auto;
    }
    a:first-child{
        display: grid;
        vertical-align: middle;
        margin-right: auto;
        margin-left: 0;
    }
    a:last-child{
        display: grid;
        vertical-align: middle;
        margin-left: auto;
        margin-right: 0;
    }
    img{
        height: 100%;
        width: 100%;
        max-height: 3.5vh;
        max-width: 3.5vh;
        min-width: 3.5vh;
    }
  }
  .container-cards{
    align-items: center;
    align-content: center;
    vertical-align: middle;
    display: grid;
    position: absolute;
    top: 18vh;
    width: 100%;
    margin: auto;
    text-align: center;
    margin-top: 1vh;
    a{
        width: 50vh;
        margin: auto;
    }
  }

  .container-social-networks{
    position: absolute;
    top: 88vh;
    width: 100%;
    height: 15vh;
  }
  .table-footer{
    width: 50vh;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    }
    .table-first-column{
        display: grid;
        grid-template-columns: repeat(4, 25%);
        width: 70%;
        position: relative;
        margin-top: -1vh;
        a{
            position: relative;
            vertical-align: middle;
            height: 100%;
            margin-right: auto;
            width: 100%;
            height: 3vh;
        }
        img{
            position: absolute;
            vertical-align: middle;
            height: 100%;
            margin-right: auto;
            bottom: 0;
            width: 100%;
            height: auto;
            max-width: 3vh;
            max-height: 3vh;
        }
        a:nth-child(3){
           
            img{
                
            }
        }
    }

    .bottom-quote-left{
        color: white;
        margin-bottom: 2vh;
        text-transform: uppercase;
    }
    .bottom-quote-right{
        color: white;
        text-align: right;
        text-transform: uppercase;
    }

    .table-second-column{
        position: relative;
    }
    .container-float-icons{
        cursor: pointer;
    }

  @media(max-width: 990px){
     .container-full-logo .center .image{
        max-width: 25vh !important;
    }
    .container-top-icons {
        width: 38vh;
        height: 4vh;
       
    }
    .container-cards{
        margin-top: 0vh;
        a{
            width: 38vh;
        }
    }
    .table-footer{
        width: 38vh;
        grid-template-columns: 60% 40%;
        font-size: 1.5vh;
    }
    .table-first-column{
        width: 100%;
    }
  }
`
