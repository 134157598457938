import React from "react"
import { Image } from '../Images'
import styled from "styled-components"
import { motion } from "framer-motion"

import '../layout/layout.css'

const LinktreeSocialNetwork = ({ linktree, open, setOpen, comerciais }) => {
    const menuVariants = {
        open: {
            x: '-19px',
            y: '-41px',
            rotate: 0,
            transition: {
                duration: 0.2
            }
        },
        closed: {
            x: '-19px',
            y: '-41px',
            rotate: -45,
            transition: {
                duration: 0.2
            }
        }
    }
    return (
        <div className="container-social-networks">
            <div className="table-footer">
                <p className="bottom-quote-left">{linktree.quoteLeftBottom}</p>
                <p className="bottom-quote-right">{linktree.quoteRightBottom}</p>
            </div>
            <div className="table-footer">
                <div className="table-first-column">
                    {linktree.bottomIcons.map((icon) => (
                        <a role="button" href={icon.link} target="_blank" rel="noreferrer">
                            <Image
                                src={icon.icon}
                                alt={icon.alt}
                                className='social-icon'
                            />
                        </a>
                    ))}
                </div>
                <div className="table-second-column">
                    <div className="container-float-icons" role="button" tabIndex={0} onClick={() => setOpen(!open)} onKeyDown={() => setOpen(!open)}>
                        <FloatIconsStyled >
                            <div className={open ? 'container-open-icon' : 'container-close-icon'} >
                                <motion.svg xmlns="http://www.w3.org/2000/svg" width="20.506" height="20.506" viewBox="0 0 20.506 20.506"
                                >
                                    <g id="Grupo_684" data-name="Grupo 684" transform="translate(-323.747 -610.747)">
                                        <circle id="Elipse_1" data-name="Elipse 1" cx="8" cy="8" r="8" transform="translate(326 613)" />
                                        <motion.g id="Grupo_673" data-name="Grupo 673"
                                            variants={menuVariants}
                                            initial="closed"
                                            animate={open ? "open" : "closed"}
                                        >
                                            <text id="_" data-name="+" transform="translate(353 667)" fill="#ebebeb" fontSize="16" fontFamily="Montserrat-Light, Montserrat" fontWeight="300"><tspan x="-4.552" y="0">+</tspan></text>
                                        </motion.g>
                                    </g>
                                </motion.svg>
                            </div>
                            {comerciais.map((icon) => (
                                <Image
                                    key={icon.name}
                                    src={icon.image}
                                    alt={icon.name}
                                    className='round-icon'
                                />
                            ))}
                        </FloatIconsStyled>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LinktreeSocialNetwork


const FloatIconsStyled = styled.div`
width: 15vh;
display: grid;
grid-template-columns: 30% 30% 30%;
position: absolute;
height: 4vh;
right: 0;
margin-top: -1vh;

.round-icon{
    width: 6.5vh;
    height: 6.5vh;
    margin-top: -0.5vh;
}
.round-icon:first-child{
z-index: 10;
}
.round-icon:nth-child(2){
z-index: 9;
}
.container-close-icon, .container-open-icon{
    position: absolute;
    z-index: 99;
    right: -2.3vh;
    top: -0.5vh;
}
.container-open-icon, .container-close-icon{
    img{
        width: 2vh;
    }
}

`