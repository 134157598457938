import React from "react"
import { Image } from '../Images'
import styled from "styled-components"

import '../layout/layout.css'
import CTAIcons from './CTAIcons'

const LinkTreePopUp = ({ open, setOpen, linktree }) => {

    return (
        <PopupStyled>
            <div id="miniForm" className={open ? "p-fixed container-mini-form-cta show" : "p-fixed container-mini-form-cta closed"}>
                <div className="container">
                    <div className="interior">
                        <span className="btn" onClick={() => setOpen(!open)} onKeyDown={() => setOpen(!open)} role="presentation">x</span>
                    </div>
                </div>
                <div className="container-popup">
                    <h4 className="title">{linktree.popup.title}</h4>
                    <div className="container-contacts-icons">
                        {linktree.popup.infos.map((info, index) => (
                            <div key={info.name} className="two-columns">

                                {index % 2 === 0 ?
                                    <>
                                        <Image
                                            src={info.imageBorder}
                                            alt={info.name} className="image-icon"
                                        />
                                        <div className="container-info">
                                            <div>
                                                <h2>{info.name}</h2>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="121.004" height="0.25" viewBox="0 0 121.004 0.25">
                                                    <path id="Caminho_6797" data-name="Caminho 6797" d="M942,890.5v121" transform="translate(1011.504 -941.875) rotate(90)" fill="none" stroke="#b6b6b6" strokeWidth="0.25" />
                                                </svg>
                                                <h3>{info.job}</h3>
                                            </div>
                                            <CTAIcons info={info} />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="container-info-inverse">
                                            <div>
                                                <h2>{info.name}</h2>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="121.004" height="0.25" viewBox="0 0 121.004 0.25">
                                                    <path id="Caminho_6797" data-name="Caminho 6797" d="M942,890.5v121" transform="translate(1011.504 -941.875) rotate(90)" fill="none" stroke="#b6b6b6" strokeWidth="0.25" />
                                                </svg>
                                                <h3>{info.job}</h3>
                                            </div>
                                            <CTAIcons info={info} />
                                        </div>
                                        <Image
                                            src={info.imageBorder}
                                            alt={info.name} className="image-icon"
                                        />
                                    </>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </PopupStyled>
    )
}

export default LinkTreePopUp

const PopupStyled = styled.div`
position: absolute;
top: 17vh;
margin: auto;
width: 100%;
.container-mini-form-cta {
    z-index: 999 !important;
    bottom: -60vh;
    width: 10vh;
    margin: auto;
    right: -25vh;
    position: relative;
    max-height: 0;
    opacity: 0;
    transition: all 0.50s ease-in-out;
    overflow: hidden;

    .container{
        right: 0;
        position: relative;
        overflow: hidden;
        text-align: right;
        width: 90%;
        margin: auto;
        padding-top: 2vh;
        font-size: 2vh;
        span{
            cursor: pointer;
        }
    }
    .container-popup{
        margin-top: 1vh;
        height: 60vh;
        .title{
            text-transform: uppercase;
            text-align: center;
        }
        .container-contacts-icons{
            height: 100%;
            align-items: center;
            align-content: center;
            vertical-align: middle;
            display: grid;
            grid-template-rows: 33% 33% 33%;
        }
        .two-columns{
            display: grid;
            grid-template-columns: 50% 50%;
            margin: 2vh auto auto auto;
            width: 80%;
            .container-info, .container-info-inverse{
                margin: auto;
                height: 100%;
                align-items: center;
                align-content: center;
                display: grid;
                h2{
                    font-size: 1.5em;
                }
                h3{
                    font-size: 1em;
                }
                svg{
                    width: 100%;
                    height: 1vh;
                }
            }
            .image-icon{
                height: 15vh;
                width: 15vh;
                object-fit: contain;
                margin: auto;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: contain !important;
                }
            }
        }
        .three-columns{
            display: grid;
            grid-template-columns: 33% 33% 33%;
            position: relative;
            margin-top: 1vh !important;
            a{
                width: 100%;
                height: 100%;
                position: relative;
                max-height: 2.5vh;
                max-width: 2.5vh;
                .image, img{
                    width: inherit;
                    height: 100%;
                    object-fit: contain !important;
                    max-height: 2.5vh;
                    max-width: 2.5vh;
                    margin-bottom: 0px;
                    margin-top: auto;
                }
            }
            
        }
    }
}
.container-mini-form-cta.show{
    max-height: 100%;
    width: 50vh;
    height: 69vh;
    right: 0;
    opacity: 1; 
    margin: auto;
    background-color: #F7F7F7;
    bottom: 0;
    transition: all 0.50s ease-in-out;
    overflow: hidden;
    :after, :before{
        opacity: 0;
    }
}

@media (max-width: 990px){
    top: 18vh;
    .container-mini-form-cta {
        bottom: -25vh;
        right: -15vh;
    }
    .container-mini-form-cta.show{
        width: 38vh;
        height: 69vh;
    }
    .container-mini-form-cta .container-popup .two-columns .image-icon{
        height: 14vh;
        width: 14vh;
    }
}
`