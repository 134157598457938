import React from "react"

import '../layout/layout.css'

import styled from "styled-components"
import { Image } from '../Images'

const RectComponent = ({ image, logo, reverse, srcImage, blog }) => {

    var className1 = "first"
    var src1 = image
    var className2 = "second bg-white"
    var src2 = logo.replace('-white', '')

    if (reverse) {
        className1 += ' reverse bg-white'
        src1 = logo.replace('-white', '')
        className2 = 'second reverse'
        src2 = image
    }

    return (
        <RectComponentStyled >
            <div className="container-images">
                <Image
                    src={src1}
                    alt='Ponto Urbano'
                    className={blog ? 'blog ' + className1 : className1}
                />
            </div>
            <div className="container-images">
                {srcImage ?
                    <img src={src2} alt='Ponto Urbano'
                        className={'image ' + className2} />
                    : <Image
                        src={src2}
                        alt='Ponto Urbano'
                        className={blog ? 'blog ' + className2 : className2}
                    />
                }
            </div>
        </RectComponentStyled>
    )
}

export default RectComponent



const RectComponentStyled = styled.div`
    --z: 25px;  /* control the zig-zag  */
    --s: 16vh; /* control the size */
    --g: 0px;   /* control the gap */
    
    display: grid;
    width: 50vh;
    grid-auto-flow: column;
    background-color: white;
    margin: auto;
    margin-bottom: 1vh;

    .container-images{
        display: grid;
        vertical-align: middle;
        align-items: center;
        align-self: center;
        alignment-baseline: central;
        .image:not(.bg-white) img{
            object-position: top right !important;
        }
    }

    .prev{
        height: 3vh !important;
        margin-bottom: -2vh;
        margin-left: -1.5vh;
        img{
            object-fit: contain !important;
            max-width: 7vh !important;
            margin: auto !important;
        }
    }

   .image {
    width: 0;
    min-width: calc(104% + var(--z)/2);
    height: var(--s);
    object-fit: cover;
    -webkit-mask: var(--mask);
            mask: var(--mask);
    cursor: pointer;
    transition: .5s;
  }
  .second.image:not(.reverse), .first.reverse.image {
    height: 8vh;
    img{
        object-fit: contain !important;
        max-width: 18vh !important;
        margin: auto !important;
    }
  }
  .second.image:not(.reverse) {
    img{
        padding-left: 2vh;
    }
  }
  .first.reverse.image {
    img{
        padding-right: 2vh;
    }
  }
  .hover-effect {
    width: calc(var(--s)/2);
  }

  .first.image{
    place-self: start;
    clip-path: polygon(0 0,100% 0,calc(100% - 2*var(--z)) 100%,0 100%);
  }
  .second.image{
    place-self: end;
    clip-path: polygon(calc(2*var(--z)) 0,100% 0,100% 100%,0 100%);
  }

  .first.reverse.image{
    place-self: start;
    clip-path: polygon(0 0,calc(100% - 2*var(--z)) 0,100% 100%,0 100%);

  }
  .second.reverse.image{
    place-self: end;
    clip-path: polygon(0 0,100% 0,100% 100%,calc(2*var(--z))  100%);
  }

  .blog.first.reverse.image{
    img{
        max-width: 15vh !important;
    }
  }
  @media(max-width: 990px){
    width: 38vh;
    --s: 16.5vh;
 }
`
