import React from "react"
import { Image } from '../Images'
import { Link } from "gatsby"

import '../layout/layout.css'

const LinktreeHeader = ({ src, alt }) => {

    return (
        <div className="container-full-logo">
            <div className="center">
                <Link to="/" >
                    <Image
                        src={src}
                        alt={alt}
                    />
                </Link>
            </div>
        </div>
    )
}

export default LinktreeHeader