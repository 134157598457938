import React from "react"

import '../layout/layout.css'
import { Image } from '../Images'
import { handleCall } from "../../utils/handleClick"

const CTAIcons = ({ info }) => {

    return (
        <div className="three-columns">
            {info.icons.map((icon, i) => (
                icon.link.includes('tel:') ?
                    <a role="button" onClick={() => handleCall(icon.link)} onKeyDown={() => handleCall(icon.link)} key={'icon-' + i}  tabIndex={0} href={() => handleCall(icon.link)}>
                        <Image
                            src={icon.icon}
                            alt={'icon-' + i}
                        />
                    </a>
                    :
                    <a href={icon.link} key={'icon-' + i} role="button" tabIndex={0} >
                        <Image
                            src={icon.icon}
                            alt={'icon-' + i}
                        />
                    </a>
            ))}
        </div>
    )
}

export default CTAIcons