import React from "react"
import { Image } from '../Images'

import '../layout/layout.css'

const LinktreeTopIcons = ({ topIcons }) => {

    return (
        <div className="center">
            <div className="container-top-icons">
                {topIcons.map((icon) => (
                    <a href={icon.link} key={icon.alt} target="_blank" rel="noreferrer">
                        <Image
                            src={icon.icon}
                            alt={icon.alt}
                        />
                    </a>
                ))}
            </div>
        </div>
    )
}

export default LinktreeTopIcons